import {isFuture} from 'date-fns'
import {DateTime} from "luxon";

export function cn(...args) {
    return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
    if (!data.edges) return []
    return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({slug}) {
    return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({publishedAt}) {
    return !isFuture(publishedAt)
}

export function getBlogUrl(slug) {
    return `/blog/${slug.current || slug}/`
}

export function buildImageObj(source = {asset: {}}) {
    const imageObj = {
        asset: {_ref: source.asset._ref || source.asset._id}
    }

    if (source.crop) imageObj.crop = source.crop
    if (source.hotspot) imageObj.hotspot = source.hotspot

    return imageObj
}

export function toPlainText(blocks) {
    if (!blocks) {
        return ''
    }
    if (Array.isArray(blocks)) {
        return blocks
            .map(block => {
                if (block._type !== 'block' || !block.children) {
                    return ''
                }
                return block.children.map(child => child.text).join('')
            })
            .join('\n\n')
    }
}

export function utcTimeStringToDate(utc: string): string {
    try {
        const date = new Date(utc)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    } catch (e) {
        // console.error('Issue parsing date')
        return ''
    }
}

export function utcTimeStringToDateTime(utc: string): string {
    try {
        const date = new Date(utc)
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
    } catch (e) {
        // console.error('Issue parsing date')
        return ''
    }
}

export function utcTimeStringtoTimeWords(utc:string): string {
    try{
        const date = new Date(utc)
        return date.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit", hour12: true})
        // .toLocaleString(DateTime.DATETIME_HUGE)
    } catch (e) {
        // console.error('Issue parsing date')
        return ''
    }
}

export function utcTimeStringtoDateWords(utc:string): string {
    try{
        return DateTime.fromJSDate(new Date(utc))
            .toFormat('EEEE d LLL y')
        // .toLocaleString(DateTime.DATETIME_HUGE)
    } catch (e) {
        // console.error('Issue parsing date')
        return ''
    }
}

export function utcTimeStringtoTimeZone(utc:string): string {
    try{
        return DateTime.fromJSDate(new Date(utc))
            .toFormat('ZZZZZ')
        // .toLocaleString(DateTime.DATETIME_HUGE)
    } catch (e) {
        // console.error('Issue parsing date')
        return ''
    }
}

export function jsDateToDdMmYyyy(date: Date): string {
    if (date) {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }
    return ''
}
